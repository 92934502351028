import React, { useState, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import '../common/css/global.css';
import './App.css';
import * as impact from '../common/api/impact';
import LandingPage from "./pages/LandingPage";
import ImpactPage from "./pages/ImpactPage";

function App() {
  const merchantCode = 'MAG_NZ_SHOP_RESENE_CO_NZ';
  const [fetchedImpact, setfetchedImpact] = useState();

  useEffect(() => {
    impact.merchants(merchantCode)
      .then(response => response.json())
      .then(body => {
        setfetchedImpact(body)
      })
      .catch(setfetchedImpact);
  }, []);

  return (
    <>

      <Switch>
        <Route
          exact
          path={`/impact`}
          key="app-resene-impact"
        >
          <ImpactPage merchantCode={merchantCode} />
        </Route>
        <Route
          path={`/`}
          key="app-resene-landing"
        >
          <LandingPage fetchedImpact={fetchedImpact} merchantCode={merchantCode} />
        </Route>
      </Switch>
    </>
  );
}

export default App;
