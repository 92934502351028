import React, { useState, useEffect } from 'react';
import Accordian from '../../common/components/Accordian/Accordian.js'
import logo from "../../common/media/cc_logo_white.svg";
import { formatNumber } from "../../common/util/numberFormatter";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { projects as getProjects } from '../../common/api/projects';
import ProjectsList from "./../Projects/ProjectsList";
import Popover from "../../common/components/popover/popover.js";
import { footerLinks } from '../../common/util/uiConfigs/footerConfiguration.js';

const LandingPage = ({ merchantCode, fetchedImpact }) => {
    const [projects, setProjects] = useState();
    
    const numberOfContributions = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.numberOfContributions?.value);
    const carbonOffsetImpact = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact.carbonOffsetImpact?.value));
    const carbonOffsetImpactUnit = fetchedImpact && !fetchedImpact.error && fetchedImpact.carbonOffsetImpact?.unit.toLowerCase()
    const treeGrowthYears = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.treeGrowthYears?.value);
    
    const treeMetric = treeGrowthYears && <span className="translate-dynamic"><span>An average tree captures about 20kg of carbon dioxide per year, and about 1 tonne over its lifetime. By funding projects that offset carbon emissions, we are having the same impact as</span> <span className="no-translate"> {treeGrowthYears}</span> <span>trees have in one year.</span></span>
        
    useEffect(() => {
        getProjects(null, merchantCode)
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(setProjects);

    }, []);

    return (
        <div>
            <div className="transparent-header">
                <div className="container">
                    <a target="_blank" href="#">
                        <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1637226323/Enterprise/Resene/logo_idw5if.png`} style={{height: '99px'}} />
                    </a>                    
                </div>
            </div>
            <div className="h-85vh w-100 position-relative img-cover hero-img vertical-align-parent bg-grey main-banner" >
               
                <div className="container color-white text-align-start vertical-align">
                    <div>
                        <div className="mw-720px">
                            <h1 className="text-shadow mt-0 text-white">Painting climate <br/>friendly has never <br/>been easier</h1>
                            <p className="text-shadow bnr-text">Resene is here to help you reduce the environmental <br/>impact of your decorating</p>
                            <a target="_blank" href="https://my.carbon.click/" className="btn btn-primary">Reduce my Impact</a>
                        </div>

                        <div className="d-flex mobile-hide">
                            <p className="mr-2 mt-0 text-light-grey mdm">Powered by</p>
                            <div>
                                <a target="_blank" href="https://www.carbonclick.com/"><img style={{ height: 26 }} src={logo} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-show">
                <div className="bg-primary p-3 text-center">
                    <div className="d-flex w-fit center color-white">
                        <p className="mr-2 font-bold mt-0 mb-0">Powered by</p>
                        <div>
                            <a className="d-flex" target="_blank" href="https://www.carbonclick.com/"><img style={{ height: 26 }} src={logo} /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-90">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1636445984/Enterprise/Resene/shop-climate-friendly_bi8wbx.png" />
                        </div>
                        <div className="col-md-6 col-lg-5">
                            <h2 className="text-left text-sm-center mb-4">Shop Climate Friendly</h2>
                            <p className="text-left text-sm-center mt-1 mb-0">The Climate Friendly Cart is a tool we use to help mitigate climate change and lower the environmental impact of online shopping. The programme supports high quality climate projects that make a real traceable difference. </p>
                            <h5 className="text-left text-sm-center mb-3 font-bold">Together we are fighting climate change.</h5>
                            <p className="text-left text-sm-center mt-1">Simply click the Green Button at checkout to contribute $2 to our carefully selected carbon offsetting projects that fight climate change.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-90 bg-grey-img">
                <div className="container">
                    <h2 className="font-bold mb-4 pb-2 mt-0">Customer Carbon Impact</h2>
                    <div className="mobile-hide">
                        <div>
                            <div className="row text-align-start">

                                <div className="col-4 d-grid">
                                    <div className="w-100">
                                        <div className="img-cover img-1 w-100">
                                            <img src={`https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637226409/Enterprise/Resene/image--304_2_axk4ww.png`}/>
                                        </div>
                                        <div className="font-bold">
                                            <h2 className="count-title text-primary mb-2">{numberOfContributions}</h2>
                                            <p className="text-black mdm mt-0">People offsetting their painting</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 d-grid">
                                    <div className="w-100">
                                        <div className="img-cover img-1 w-100">
                                            <img src={`https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637226409/Enterprise/Resene/image_66_gimrmp.png`} />
                                        </div>
                                        <div className="font-bold">
                                            <h2 className="count-title text-primary mb-2">{carbonOffsetImpact}</h2>
                                            <p className="text-black mdm mt-0"><span className="capitalize">{carbonOffsetImpactUnit}</span> of carbon offset</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 d-grid">
                                    <div className="w-100">
                                        <div className="img-cover img-1 w-100">
                                            <img src={`https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637226409/Enterprise/Resene/image_65_swuadj.png`} />
                                        </div>
                                        <div className="font-bold">
                                            <h2 className="count-title text-primary mb-2">{treeGrowthYears}</h2>
                                            <p className="text-black mdm mt-0"><span className="mr-2">Tree Growth Years</span>
                                                <Popover content={treeMetric} >
                                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="8.03418" cy="7.5" r="7.5" fill="#69A046"/>
                                                    <path d="M7.27026 9.52332C7.04576 9.52332 6.86377 9.34132 6.86377 9.11682V9.11682C6.86377 8.76526 6.93884 8.45947 7.08899 8.19946C7.23914 7.93945 7.51379 7.66296 7.91296 7.37C8.29749 7.09534 8.55017 6.87195 8.67102 6.69983C8.79553 6.52771 8.85779 6.33545 8.85779 6.12305C8.85779 5.88501 8.7699 5.70374 8.59412 5.57922C8.41833 5.45471 8.17297 5.39246 7.85803 5.39246C7.47891 5.39246 7.06315 5.47793 6.61076 5.64889C6.25443 5.78354 5.84086 5.65249 5.67113 5.31148V5.31148C5.50843 4.98458 5.6293 4.58261 5.96638 4.44222C6.60834 4.17483 7.27917 4.04114 7.97888 4.04114C8.73328 4.04114 9.33203 4.22241 9.77515 4.58496C10.2219 4.94751 10.4453 5.43091 10.4453 6.03516C10.4453 6.43799 10.3538 6.78589 10.1707 7.07886C9.98755 7.37183 9.63965 7.70142 9.12695 8.06763C8.77539 8.32764 8.552 8.52539 8.45679 8.66089C8.36523 8.79639 8.31946 8.974 8.31946 9.19373V9.19373C8.31946 9.37575 8.1719 9.52332 7.98987 9.52332H7.27026ZM6.68799 11.402C6.68799 11.0944 6.77039 10.8618 6.93518 10.7043C7.09998 10.5469 7.33984 10.4681 7.65479 10.4681C7.95874 10.4681 8.19312 10.5487 8.35791 10.7098C8.52637 10.871 8.6106 11.1017 8.6106 11.402C8.6106 11.6913 8.52637 11.9202 8.35791 12.0886C8.18945 12.2534 7.95508 12.3358 7.65479 12.3358C7.34717 12.3358 7.10913 12.2552 6.94067 12.0941C6.77222 11.9293 6.68799 11.6986 6.68799 11.402Z" fill="white"/>
                                                    </svg>
                                                </Popover>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="mobile-show mt-4 text-center">
                    <div className="container">
                        <Carousel dynamicHeight={false} showThumbs={false} showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={true}>
                            <div key="1">
                                <div className="w-100">
                                    <div className="img-cover img-1 w-100">
                                        <img src={`https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637226409/Enterprise/Resene/image--304_2_axk4ww.png`}/>
                                    </div>
                                    <div className="font-bold">
                                        <h2 className="count-title text-primary mb-2">{numberOfContributions}</h2>
                                        <p className="text-black mdm mt-0">People offsetting their painting</p>
                                    </div>
                                </div>
                            </div>

                            <div key="2">
                                <div className="w-100">
                                    <div className="img-cover img-1 w-100">
                                        <img src={`https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637226409/Enterprise/Resene/image_66_gimrmp.png`} />
                                    </div>
                                    <div className="font-bold">
                                        <h2 className="count-title text-primary mb-2">{carbonOffsetImpact}</h2>
                                        <p className="text-black mdm mt-0">{carbonOffsetImpactUnit} of carbon Offset</p>
                                    </div>
                                </div>
                            </div>

                            <div key="3">
                                <div className="w-100">
                                    <div className="img-cover img-1 w-100">
                                        <img src={`https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637226409/Enterprise/Resene/image_65_swuadj.png`} />
                                    </div>
                                    <div className="font-bold">
                                        <h2 className="count-title text-primary mb-2">{treeGrowthYears}</h2>
                                        <p className="text-black mdm mt-0"><span className="mr-2">Tree Growth Years</span>
                                            <Popover content={treeMetric} >
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8.03418" cy="7.5" r="7.5" fill="#69A046"/>
                                                <path d="M7.27026 9.52332C7.04576 9.52332 6.86377 9.34132 6.86377 9.11682V9.11682C6.86377 8.76526 6.93884 8.45947 7.08899 8.19946C7.23914 7.93945 7.51379 7.66296 7.91296 7.37C8.29749 7.09534 8.55017 6.87195 8.67102 6.69983C8.79553 6.52771 8.85779 6.33545 8.85779 6.12305C8.85779 5.88501 8.7699 5.70374 8.59412 5.57922C8.41833 5.45471 8.17297 5.39246 7.85803 5.39246C7.47891 5.39246 7.06315 5.47793 6.61076 5.64889C6.25443 5.78354 5.84086 5.65249 5.67113 5.31148V5.31148C5.50843 4.98458 5.6293 4.58261 5.96638 4.44222C6.60834 4.17483 7.27917 4.04114 7.97888 4.04114C8.73328 4.04114 9.33203 4.22241 9.77515 4.58496C10.2219 4.94751 10.4453 5.43091 10.4453 6.03516C10.4453 6.43799 10.3538 6.78589 10.1707 7.07886C9.98755 7.37183 9.63965 7.70142 9.12695 8.06763C8.77539 8.32764 8.552 8.52539 8.45679 8.66089C8.36523 8.79639 8.31946 8.974 8.31946 9.19373V9.19373C8.31946 9.37575 8.1719 9.52332 7.98987 9.52332H7.27026ZM6.68799 11.402C6.68799 11.0944 6.77039 10.8618 6.93518 10.7043C7.09998 10.5469 7.33984 10.4681 7.65479 10.4681C7.95874 10.4681 8.19312 10.5487 8.35791 10.7098C8.52637 10.871 8.6106 11.1017 8.6106 11.402C8.6106 11.6913 8.52637 11.9202 8.35791 12.0886C8.18945 12.2534 7.95508 12.3358 7.65479 12.3358C7.34717 12.3358 7.10913 12.2552 6.94067 12.0941C6.77222 11.9293 6.68799 11.6986 6.68799 11.402Z" fill="white"/>
                                                </svg>
                                            </Popover>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            {Array.isArray(projects) &&
                <div className="py-90">
                    <div className="container">
                        <div className="mb-4 pb-3">
                            <h2 className="font-bold mb-3 pb-2">Supporting Projects <br/>That Fight Climate Change</h2>
                            <p className="mdm text-grey">Your contributions support only the highest quality New Zealand climate projects, to bring you peace of mind and real impact slowing climate change.</p>
                        </div>
                        <ProjectsList title={null} projects={projects} />
                    </div>
                </div>
            }

            <div className="py-90 bg-green-img">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <img className="n-space" src="https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637226653/Enterprise/Resene/2014_E_Choice_cans_EXTERIOR_2OPT_Transp_moneyb.png" />
                        </div>
                        <div className="col-md-7 text-white">
                            <h2 className="text-left text-sm-center">Our favourite colour is green! </h2>
                            <p className="text-left text-sm-center mt-3 mb-0 mdm line-30">Resene’s commitment extends to helping the community in which we operate.  Resene has been working with Trees that Count, a New Zealand organisation focused on planting native trees for local habitat regeneration, through the Resene Paint It Green programme.  The trees planted from the Resene Paint it Green programme are estimated to remove 470 tonnes of carbon, with more trees being planted every year.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-90 bg-black-img text-white">
                <div className="container">
                    <div className="mb-4 pb-3">
                        <h2 className="font-bold mb-4">Our Initiatives</h2>
                        <p className="lrg mt-0">Resene has adopted a multi-pronged approach to measuring and reducing carbon, focusing on a range of internal and external projects to provide opportunities to continually improve. </p>
                    </div>
                    <ul className="lineBoxes">
                        <li>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <h5 className="lrg">Quality Paint</h5>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-0 xsml my-3 line-22">Resene’s strongest commitment is to provide quality products.  Quality paints, coatings and wood stains made using quality ingredients, designed in New Zealand for New Zealand’s harsh conditions, give you a durable finish that protects the substrate it's on, reducing the need to repair or replace.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <h5 className="lrg">Environmental Choice</h5>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-0 xsml my-3 line-22">Resene has the most extensive range of Environmental Choice products, with everything from interior and exterior paints to wood stains and clear finishes. These paints are waterbased, lower in VOC and odour and easier to clean up and reuse application tools for other projects.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <h5 className="lrg">Resene Eco.Decorators</h5>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-0 xsml my-3 line-22">Our nationwide network of Resene Eco.Decorators provide a quality paint finish, while working hard to focus on Environmental Choice paint use, minimising paint wastes and minimising wash water using the Resene WashWise system, reducing their carbon footprint vs more toxic and wasteful processes.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <h5 className="lrg">Resene PaintWise</h5>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-0 xsml mt-3 line-22">Resene PaintWise recycling, the only paint return service accredited by the Ministry for the Environment, takes care of the unwanted paint packaging and paint waste for you.  The returns are recycled and reused in New Zealand – no waste is sent offshore.  Recycled plastic packaging is made into ‘new’ 100% recycled pails, recycled cans are reused in metal manufacture, solventborne paint is sent to solvent recovery and the waterborne paint is donated to councils and community groups to cover graffiti and used in other industries.</p>
                                    <p className="mb-0 xsml mb-3 line-22">Over 250,000L of paint has been donated, enough to cover over 2 square million metres of graffiti.  In over 15 years of running this programme, over 6 million packs have been returned and recycled. </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="text-right pt-4">
                        <a target="_blank" href="https://www.resene.co.nz/comn/envissue/howgreen.htm" className="btn btn-primary px-3 w-auto">Learn More</a>
                    </div>
                </div>
            </div>

            <div className="py-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="https://res.cloudinary.com/carbonclick/image/upload/f_auto,q_auto/v1637227147/Enterprise/Resene/thumsUp_unznfq.png" />
                        </div>
                        <div className="col-md-6 pt-5">
                        <h2 className="font-bold text-black mt-0">Climate Friendly FAQs</h2>
                            <div className="mb-3 center text-align-start">
                                <Accordian title={"Who is CarbonClick?"} >CarbonClick is a New Zealand company that exists to reverse climate change by empowering businesses and their customers to take climate action. By making carbon offsets simple, trustworthy and cost effective, CarbonClick is connecting millions of people to high quality offsetting projects that restore nature and reduce pollution, building a happier and more sustainable future for everyone.</Accordian>
                                <Accordian title={"What does carbon offsetting do?"} >CarbonClick follows industry best practice to ensure that the offsets provided have a real, measurable impact on mitigating climate change. Projects are scrutinised against their independently verified selection methodology, which uses many of the same stringent requirements of ICROA Code of Best Practice, such as true additionality, ensuring credible baseline reporting and verifying project developers integrity. Only projects that have considerable co-benefits are considered, such as improving biodiversity, creating sustainable jobs, empowering women and minorities and improving community health and wellbeing. </Accordian>
                                <Accordian title={"What are carbon credits?"} >A carbon credit is mechanism used to measure and support projects that fight climate change. For every tonne of CO2 that a project manages to absorb or otherwise reduce, a carbon credit is issued. Carbon credits are certified by international standards and held in registries like the Gold Standard registry (international credits) and can be purchased to reduce the carbon impact of an initiative or activity.</Accordian>
                                <Accordian title={"Where does the money go?"} >To enable their unique track and trace feature, CarbonClick pre-purchases carbon credits. This transparent reimbursement model means that you can immediately see the retirement certificates from the projects you are supporting and know your contribution is having a direct impact. A key feature of a carbon offsetting project is additionality, which means the project could not go ahead without the finance of carbon credits. We rigorously analyse our projects to ensure that additionality criteria is being met and your money is having a direct impact.</Accordian>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="w-100 img-cover footer-img py-6" >
                <div className="container color-white py-5">
                    <p className="xxsml"><b>POWERED BY</b></p>
                    <div className="d-flex">
                        <div>
                            <a href={footerLinks?.carbonclick} target="_blank" rel="noopener noreferrer">
                                <img style={{ height: 30 }} className="footer-logo" src={logo} alt="carbonclick" />
                            </a>
                        </div>
                        <div className="right">
                            <a className="text-white medium" href={footerLinks?.myCarbonClick} target="_blank" rel="noopener noreferrer">Log in to <u className="text-primary"> My CarbonClick </u> to see the impact</a>
                        </div>
                    </div>
                    <hr className="mb-4" />

                    <div className="row justify-content-between bottom-links">
                        <div className="col-md-auto">
                            <div>
                                <div className="mb-3"><a className="color-white" href={footerLinks?.aboutCarbonClick} target="_blank" rel="noopener noreferrer">About CarbonClick</a></div>
                                <div className="mb-3"><a className="color-white" href={footerLinks?.faq} target="_blank" rel="noopener noreferrer">FAQ</a></div>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div>
                                <div className="mb-3"><a className="color-white" href={footerLinks?.termsAndConditions} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></div>
                                <div className="mb-3"><a className="color-white" href={footerLinks?.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy policy</a></div>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div>
                                <div className="mb-3"><a className="color-white" href="mailto:hello+resenenz@carbonclick.com" target="_blank" rel="noopener noreferrer">hello@carbonclick.com</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default LandingPage;