import React from "react";
import SingleColumn from "./SingleColumn";
import PropTypes from "prop-types";

const ProjectsList = ({ projects, title, numProjectsToShow, showLearnMore = true }) => {

    const projectsToDisplay = projects && projects.length ? projects
        .filter((a) => {
            if (!a.name) {
                return;
            }
            return !a.name.toLowerCase().includes("sasaboe");
        })
        .sort((a, b) => a.rank - b.rank)
    : [];

    return (
        <>
            <div className="row justify-content-evenly">
                {
                    projectsToDisplay ? projectsToDisplay.slice(0,2).map((project) => {
                        return <div className="col-md-4 col-sm-6" key={project.id}>
                            <SingleColumn project={project} showLearnMore={showLearnMore} />
                        </div>
                    }) : ''
                }
            </div>
        </>
    )
};

ProjectsList.propTypes = {
    title: PropTypes.string,
    projects: PropTypes.array,
    numProjectsToShow: PropTypes.string
};

export default ProjectsList;