import React from "react";
import PropTypes from "prop-types";
import { getLinkToProject } from "../../common/util/pathUtils";

const SingleColumn = ({ project, showLearnMore }) => {
    const linkToProject = getLinkToProject(project.code);

    return <div className="w-100 con-justify text-left">
					<div className="up">
						<div className="img-cover img-1 w-100">
							<a href={linkToProject}><img src={project.thumbnailImageUrl} /></a>
						</div>
						<div className="py-2">
							<h6 className="text-grey">{project.locationDescription}</h6>
							<h5><a href={linkToProject} className="text-black font-bold">{project.name}</a></h5>
							<p className="text-grey xsml mt-0 line-22">{project.shortDescription}</p>
						</div>
					</div>
					<div className="down">
						{showLearnMore &&
						<a href={linkToProject} className="text-primary font-bold xsml">LEARN MORE
							<img className="ml-2" src={`https://res.cloudinary.com/carbonclick/image/upload/v1636449497/Enterprise/Resene/arwo_h0v0sl.svg`} />
						</a>
						}
					</div>
				</div>
}

SingleColumn.propTypes = {
    project: PropTypes.object,
    showLearnMore: PropTypes.bool
}

export default SingleColumn;